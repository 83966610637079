import React, { useState, useEffect, useRef } from "react";
import useCar from "Hooks/RentCarHooks/useCar";
import useLocations from "Hooks/LocationsHooks/useLocations";
import { useAuth } from "Hooks/AuthHooks/useAuth";
import { Input, Select, DatePicker } from "antd";
import Button from "Components/Button";
import { SearchOutlined } from "@ant-design/icons";
import HeadingTitle from "Components/Headings/HeadingTitle";

const { RangePicker } = DatePicker;

const FilterRentCars = ({
    setQuery,
    actionSetCars,
    actionSearch,
    actionSetCurrentPage,
    actionSetLastPage,
    actionPage,
    actionTotalCars,
    loading,
    clearance,
}) => {
    const { getCarCategories, filterCars } = useCar();
    const transmissionOptions = [
        {
            value: "automatic",
            label: "Automatic",
        },
        {
            value: "manual",
            label: "Manual",
        },
    ];

    const { isUserAdmin } = useAuth();
    const { getLocations } = useLocations();

    const [locations, setLocations] = useState(null);
    const [categories, setCategories] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [searchLocation, setSearchLocation] = useState(null);
    const [carName, setCarName] = useState(null);
    const [carLicencePlate, setCarLicencePlate] = useState(null);
    const [category, setCategory] = useState(null);
    const [carTransmissionType, setCarTransmissionType] = useState(null);
    const [filterActive, setFilterActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [defaultRangePicker, setDefaultRangePicker] = useState(null);
    const [selectedDayRange, setSelectedDayRange] = useState({
        from: null,
        to: null,
    });

    const fetchCarCategories = async () => {
        const categories = await getCarCategories();
        setCategories(categories);
    };

    const fetchLocationsData = async () => {
        const locations = await getLocations();
        setLocations(locations);
    };

    // Query builder for filtering results of cars
    const buildUrlQuery = async (locationId = null) => {
        const query = new URLSearchParams();
        if (carName) {
            query.append("car_name", carName);
        }
        if (locationId) {
            query.append("location", locationId);
        }
        if (category && category !== "default") {
            query.append("category", category);
        }
        if (carTransmissionType) {
            query.append("car_transmission_type", carTransmissionType);
        }
        if (carLicencePlate) {
            query.append("car_licence_number", carLicencePlate);
        }
        if (selectedDayRange?.from) {
            const from = selectedDayRange.from
                ? `${selectedDayRange?.from?.year}-${selectedDayRange?.from?.month}-${selectedDayRange?.from?.day}`
                : null;
            const to = selectedDayRange.to
                ? `${selectedDayRange?.to?.year}-${selectedDayRange?.to?.month}-${selectedDayRange?.to?.day}`
                : `${selectedDayRange?.from?.year}-${selectedDayRange?.from?.month}-${selectedDayRange?.from?.day}`;

            query.append("from", from);
            query.append("to", to);
        }
        if (query.toString()) {
            setIsLoading(true);
            loading(true);
            setFilterActive(true);
            actionSearch(true);

            setSearchQuery(query.toString());
            setQuery(query.toString());
            const cars = await filterCars(
                query.toString(),
                actionPage,
                setIsLoading,
            );
            actionSetCurrentPage(cars?.current_page || 1);
            actionSetLastPage(cars?.last_page);
            actionSetCars(cars?.data);
            actionTotalCars(cars?.total);
            setIsLoading(false);
            loading(false);
        }
    };

    const clearFilters = async () => {
        setFilterActive(false);
        actionSearch(false);
        setSearchQuery(null);
        setQuery("");
        setSelectedDayRange({
            from: null,
            to: null,
        });
        setCarTransmissionType(null);
        setCategory(null);
        setSearchLocation(null);
        setCarName(null);
        setCarLicencePlate(null);
        setDefaultRangePicker(null);
        clearance(1);
    };

    const handleSelectedDayRange = (date) => {
        const from = date[0];
        const to = date[1];

        const mapFromData = {
            year: from.$y,
            month: from.$M + 1,
            day: from.$D,
        };

        const mapToData = {
            year: to.$y,
            month: to.$M + 1,
            day: to.$D,
        };

        setSelectedDayRange({
            from: mapFromData,
            to: mapToData,
        });
        setDefaultRangePicker(date);
    };

    const handleLocationChange = (uuid) => {
        setSearchLocation(uuid);
        buildUrlQuery(uuid);
    };

    useEffect(() => {
        fetchCarCategories();
        if (isUserAdmin()) fetchLocationsData();
        if (searchQuery) {
            buildUrlQuery(searchLocation);
        }
    }, [actionPage, selectedDayRange, searchLocation]);

    return (
        <>
            {isUserAdmin() && (
                <div className={"flex gap-2 items-center"}>
                    <HeadingTitle title={"LOCATION"} />
                    <Select
                        value={searchLocation}
                        onChange={handleLocationChange}
                        placeholder={"Select location..."}
                        className={"sm:w-full w-[250px]"}
                        disabled={!locations?.length > 0}
                        options={locations?.map((location) => {
                            const { name, address, uuid } = location;
                            return {
                                value: uuid,
                                label: `${name} - ${address}`,
                            };
                        })}
                    />
                </div>
            )}

            <div className="sm:w-full flex gap-3 items-center flex-wrap">
                <HeadingTitle title={"FILTER CARS"} />
                <Input
                    onChange={(e) => setCarName(e.target.value)}
                    type="text"
                    placeholder="Search by name..."
                    value={carName}
                    className="sm:w-full w-36"
                />
                <Input
                    onChange={(e) => setCarLicencePlate(e.target.value)}
                    type="text"
                    placeholder="Licence number..."
                    value={carLicencePlate}
                    className="sm:w-full w-36 "
                />
                <Select
                    value={category}
                    onChange={(uuid) => setCategory(uuid)}
                    placeholder={"Category..."}
                    className={"sm:w-full w-[150px]"}
                    disabled={!categories?.length > 0}
                    options={categories?.map((category) => {
                        const { category_name, uuid } = category;
                        return {
                            value: uuid,
                            label: category_name,
                        };
                    })}
                />

                <Select
                    value={carTransmissionType}
                    placeholder={"Select transmission..."}
                    className={"sm:w-full w-[150px]"}
                    onChange={(type) => setCarTransmissionType(type)}
                    options={transmissionOptions}
                />
                <RangePicker
                    className={"sm:w-full w-[250px]"}
                    onChange={handleSelectedDayRange}
                    value={defaultRangePicker}
                />
                <div className="sm:mt-3 lg:mt-3 sm:w-full text">
                    <Button
                        onClick={() => buildUrlQuery(searchLocation)}
                        isLoading={isLoading}
                        buttonType="primary"
                    >
                        Search <SearchOutlined />
                    </Button>
                </div>
                {filterActive ? (
                    <div className={"sm:text-center sm:w-full"}>
                        <Button onClick={clearFilters}>Clear Filters</Button>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default FilterRentCars;
