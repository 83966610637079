import React, { useContext, useEffect, useState } from "react";
import { ReservationContext } from "Context/ReservationsContext/ReservationContext";
import {
    Avatar,
    Button,
    Divider,
    Modal,
    Table,
    TableProps,
    Tag,
    Tooltip,
} from "antd";
import LoadingPlaceholder from "Components/Placeholders/LoadingPlaceholder";
import { LocationTypes } from "Types/LocationTypes";
import ReactCountryFlag from "react-country-flag";
import MomentDateUtils from "Utils/MomentDateUtils";
import { ReservationStatus } from "Types/ReservationTypes";
import ReservationStatusHandler from "./ReservationStatusHandler";
import { isReservationValidForAction } from "Helpers/ReservationHelper";
import { addPageQueryParam } from "Helpers/PageHelper";
import useReservation from "Hooks/ReservationHooks/useReservation";
import DisplayJsonPreview from "../../../../Components/DisplayJsonPreview/DisplayJsonPreview";

interface DataType {
    key: string;
    name: string;
    country: string;
    phone: string;
    email: string;
    age: string;
    pickUpTime: string;
    dropOffTime: string;
    pickUpLocation: LocationTypes;
    dropOffLocation: LocationTypes;
    status: ReservationStatus;
    showModal: (key: string, accept: string) => void;
    showCustomerModal: (
        name: string,
        country: string,
        dateOfBirth: string,
        phone: string,
        email: string,
    ) => void;
    showBookingDataModal: Function;
    integration: string;
    totalPrice: string;
    totalDays: string;
    fullAmount: string | undefined;
    additionalData: any;
    accessories: [];
}
const columns: TableProps<DataType>["columns"] = [
    {
        title: "Booking Number",
        dataIndex: "bookingNumber",
        key: "bookingNumber",
    },
    {
        title: "Customer",
        dataIndex: "name",
        key: "name",
        render: (_, record) => {
            const { name, country, email, phone, age } = record;
            return (
                <Button
                    className={"uppercase"}
                    onClick={() =>
                        record.showCustomerModal(
                            name,
                            country,
                            age,
                            phone,
                            email,
                        )
                    }
                    type={"link"}
                >
                    {record.name}
                </Button>
            );
        },
    },
    {
        title: "Pick Up",
        dataIndex: "pickUp",
        key: "pickUp",
        render: (_, record) => (
            <div className={"flex items-center justify-center gap-3"}>
                <div>
                    <Tooltip
                        className={"cursor-pointer "}
                        title={
                            <>
                                <p className={"!text-white text-center"}>
                                    {record.pickUpLocation.name}
                                </p>
                                <p className={"!text-white text-center"}>
                                    {record.pickUpLocation.city}
                                </p>
                                <p className={"!text-white text-center"}>
                                    {record.pickUpLocation.address}
                                </p>
                            </>
                        }
                    >
                        <Avatar
                            size={35}
                            icon={
                                <ReactCountryFlag
                                    countryCode={
                                        record.pickUpLocation.country_code
                                    }
                                    svg
                                    style={{ width: "35px", height: "35px" }}
                                />
                            }
                        />
                    </Tooltip>
                </div>
                <div className={"font-semibold"}>
                    {MomentDateUtils.formateDateTime(record.pickUpTime)}
                </div>
            </div>
        ),
    },
    {
        title: "Drop Off",
        dataIndex: "dropOff",
        key: "dropOff",
        render: (_, record) => (
            <div className={"flex items-center justify-center gap-3"}>
                <div>
                    <Tooltip
                        className={"cursor-pointer "}
                        title={
                            <>
                                <p className={"!text-white text-center"}>
                                    {record.dropOffLocation.name}
                                </p>
                                <p className={"!text-white text-center"}>
                                    {record.dropOffLocation.city}
                                </p>
                                <p className={"!text-white text-center"}>
                                    {record.dropOffLocation.address}
                                </p>
                            </>
                        }
                    >
                        <Avatar
                            size={35}
                            icon={
                                <ReactCountryFlag
                                    countryCode={
                                        record.dropOffLocation.country_code
                                    }
                                    svg
                                    style={{ width: "35px", height: "35px" }}
                                />
                            }
                        />
                    </Tooltip>
                </div>
                <div className={"font-semibold"}>
                    {MomentDateUtils.formateDateTime(record.dropOffTime)}
                </div>
            </div>
        ),
    },
    {
        title: "Base Amount",
        dataIndex: "price",
        key: "price",
        render: (_, record) => (
            <>
                <div className={"text-end"}>
                    <p className={"text-[15px] font-bold"}>
                        {record.totalPrice} {record.pickUpLocation.currency}
                    </p>
                    <p className={"font-semibold text-gray-200"}>
                        {record.totalDays} day/s
                    </p>
                </div>
            </>
        ),
    },
    {
        title: "Full Integration Amount",
        dataIndex: "fullAmount",
        key: "fullAmount",
        render: (_, record) => (
            <>
                <div className={"text-[15px] font-bold text-green-500"}>
                    {record.fullAmount ? (
                        record.fullAmount
                    ) : (
                        <span className={"text-red-500"}>Not Added</span>
                    )}
                </div>
            </>
        ),
    },
    {
        title: "Integration",
        dataIndex: "integration",
        key: "integration",
        render: (_, record) => (
            <Tag className={"font-semibold"} color={"magenta-inverse"}>
                {record.integration}
            </Tag>
        ),
    },
    {
        title: "Booking Data",
        dataIndex: "additionalData",
        key: "additionalData",
        fixed: true,
        render: (_, record) => (
            <>
                {record.additionalData && (
                    <Button
                        type="link"
                        onClick={() =>
                            record.showBookingDataModal(record.additionalData)
                        } // Trigger modal on click
                    >
                        View Booking Data
                    </Button>
                )}
            </>
        ),
    },
    {
        title: "Accessories",
        dataIndex: "accessories",
        key: "accessories",
        fixed: true,
        render: (_, record) => (
            <>
                {record.accessories &&
                    record.accessories?.map((item) => (
                        <p className={"text-xs"}>
                            {/*// @ts-ignore*/}
                            {item.id} x {item.qty}
                        </p>
                    ))}
            </>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        fixed: true,
        render: (_, record) => (
            <ReservationStatusHandler status={record.status} />
        ),
    },

    {
        title: "Action",
        key: "action",
        fixed: true,
        render: (_, record) => (
            <div className={"flex gap-3 justify-center"}>
                {isReservationValidForAction(record.status) ? (
                    <>
                        <Button
                            onClick={() =>
                                record.showModal(record.key, "ACCEPT")
                            }
                            type={"primary"}
                        >
                            Accept
                        </Button>
                        <Button
                            onClick={() =>
                                record.showModal(record.key, "REJECT")
                            }
                        >
                            Reject
                        </Button>
                    </>
                ) : (
                    <>
                        <p className={"font-bold"}>
                            Actions are not available.
                        </p>
                    </>
                )}
            </div>
        ),
    },
];

const ReservationList = () => {
    // @ts-ignore
    const { reservations, fetchReservations, loading, curPage } =
        useContext(ReservationContext);
    const { updateReservationStatus } = useReservation();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
    const [isBookingDataModalOpen, setIsBookingDataModalOpen] = useState(false);
    const [bookingData, setBookingData] = useState<any>(null);

    const [customerModalData, setCustomerModalData] = useState({
        name: "",
        country: "",
        dateOfBirth: "",
        phone: "",
        email: "",
    });
    const [modalData, setModalData] = useState({
        uuid: "",
        action: "",
    });

    const showModal = (reservationId: string, action: string) => {
        setIsModalOpen(true);
        setModalData({
            uuid: reservationId,
            action,
        });
    };

    const handleOk = async () => {
        const data: { uuid: string; status: ReservationStatus } = {
            uuid: modalData.uuid,
            status: modalData.action === "ACCEPT" ? "CONFIRMED" : "REJECTED",
        };
        await updateReservationStatus(data).finally(() => {
            setIsModalOpen(false);
            fetchReservations();
        });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const showBookingDataModal = (data: any) => {
        setBookingData(data); // Set booking data
        setIsBookingDataModalOpen(true); // Open modal
    };
    const handleBookingDataModalCancel = () => {
        setIsBookingDataModalOpen(false);
    };
    const showCustomerModal = (
        name: string,
        country: string,
        dateOfBirth: string,
        phone: string,
        email: string,
    ) => {
        setCustomerModalData({
            name,
            country,
            dateOfBirth,
            phone,
            email,
        });
        setIsCustomerModalOpen(true);
    };

    const handleCustomerModalCancel = () => {
        setIsCustomerModalOpen(false);
    };

    const data = reservations?.map((reservation) => {
        return {
            key: reservation.uuid,
            name: reservation.driver_name,
            bookingNumber: reservation.booking_number || "Not Added",
            country: reservation.driver_country,
            phone: reservation.driver_phone,
            email: reservation.driver_email,
            age: reservation.driver_birth_date,
            pickUpTime: reservation.pick_up_datetime,
            dropOffTime: reservation.drop_off_datetime,
            pickUpLocation: reservation.pick_up_location,
            dropOffLocation: reservation.drop_off_location,
            status: reservation.status,
            integration: reservation.integration_name,
            totalPrice: reservation.total_price,
            totalDays: reservation.total_days,
            fullAmount: reservation.full_integration_amount,
            additionalData: reservation.additional_integration_data,
            accessories: reservation.accessories,
            showModal: showModal,
            showCustomerModal: showCustomerModal,
            showBookingDataModal: showBookingDataModal,
        };
    });

    useEffect(() => {
        fetchReservations();
        addPageQueryParam(curPage);
    }, [curPage]);

    // @ts-ignore
    return (
        <>
            {loading ? (
                <LoadingPlaceholder />
            ) : (
                <>
                    <Table
                        className={"[&_th]:!text-center 2xl:overflow-auto"}
                        pagination={false}
                        rowClassName={"[&_td]:!text-center"}
                        columns={columns}
                        dataSource={data}
                    />
                    <Modal
                        title="Are you sure?"
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText={"Yes"}
                    >
                        <p className={"text-[14px]"}>
                            You are about to{" "}
                            {modalData.action === "ACCEPT" ? (
                                <span className={"text-green font-bold"}>
                                    {" "}
                                    {modalData.action}{" "}
                                </span>
                            ) : (
                                <span className={"text-red font-bold"}>
                                    {" "}
                                    {modalData.action}{" "}
                                </span>
                            )}{" "}
                            current reservation?
                        </p>
                    </Modal>
                    <Modal
                        title="Customer Information"
                        open={isCustomerModalOpen}
                        onCancel={handleCustomerModalCancel}
                        okButtonProps={{ hidden: true }}
                    >
                        <Divider />
                        <div className={"[&_p]:text-[15px]"}>
                            <p>Name: {customerModalData.name}</p>
                            <p>Country: {customerModalData.country}</p>
                            <p>Age: {customerModalData.dateOfBirth}</p>
                            <p>Phone: {customerModalData.phone}</p>
                            <p>Email: {customerModalData.email}</p>
                        </div>
                    </Modal>
                    {/* Booking Data Modal */}
                    <Modal
                        width={"100%"}
                        title="Booking Data"
                        open={isBookingDataModalOpen}
                        onCancel={handleBookingDataModalCancel}
                        footer={[
                            <Button
                                key="ok"
                                type="primary"
                                onClick={handleBookingDataModalCancel}
                            >
                                OK
                            </Button>,
                        ]}
                    >
                        {bookingData && (
                            <DisplayJsonPreview data={bookingData} />
                        )}
                    </Modal>
                </>
            )}
        </>
    );
};
export default ReservationList;
